.book-now-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }
  
  .book-now-container button {
    width: 300px;
  }
  