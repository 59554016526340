.hero-container {
    /* background-color: #fff; */
    text-align: center;
    padding: 0px 40px;
  }
  
  .hero-title {
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .hero-subtitle {
    margin-bottom: 30px;
  }
  