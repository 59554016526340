@-webkit-keyframes turn {
  50% {
    -webkit-transform: rotateX(5deg) rotateY(0.5turn);
    -ms-transform: rotateX(5deg) rotateY(0.5turn);
    transform: rotateX(5deg) rotateY(0.5turn);
  }
  100% {
    -webkit-transform: rotateX(-5deg) rotateY(1turn);
    -ms-transform: rotateX(-5deg) rotateY(1turn);
    transform: rotateX(-5deg) rotateY(1turn);
  }
}
@-moz-keyframes turn {
  50% {
    -webkit-transform: rotateX(5deg) rotateY(0.5turn);
    -ms-transform: rotateX(5deg) rotateY(0.5turn);
    transform: rotateX(5deg) rotateY(0.5turn);
  }
  100% {
    -webkit-transform: rotateX(-5deg) rotateY(1turn);
    -ms-transform: rotateX(-5deg) rotateY(1turn);
    transform: rotateX(-5deg) rotateY(1turn);
  }
}
@-o-keyframes turn {
  50% {
    -webkit-transform: rotateX(5deg) rotateY(0.5turn);
    -ms-transform: rotateX(5deg) rotateY(0.5turn);
    transform: rotateX(5deg) rotateY(0.5turn);
  }
  100% {
    -webkit-transform: rotateX(-5deg) rotateY(1turn);
    -ms-transform: rotateX(-5deg) rotateY(1turn);
    transform: rotateX(-5deg) rotateY(1turn);
  }
}
@keyframes turn {
  50% {
    -webkit-transform: rotateX(5deg) rotateY(0.5turn);
    -ms-transform: rotateX(5deg) rotateY(0.5turn);
    transform: rotateX(5deg) rotateY(0.5turn);
  }
  100% {
    -webkit-transform: rotateX(-5deg) rotateY(1turn);
    -ms-transform: rotateX(-5deg) rotateY(1turn);
    transform: rotateX(-5deg) rotateY(1turn);
  }
}
@media only screen and (max-width: 600px) {
  body .container {
    overflow-x: clip;
  }
  .carousel {
    width: 189px !important;
    height: 252px !important;
  }
  .carousel .slide {
    width: 189px !important;
    height: 252px !important;
  }
  .carousel .slide:nth-child(1) {
    -webkit-transform: rotateY(0deg) translateZ(250px) !important;
    -ms-transform: rotateY(0deg) translateZ(250px) !important;
    transform: rotateY(0deg) translateZ(250px) !important;
  }
  .carousel .slide:nth-child(2) {
    -webkit-transform: rotateY(51.4285714286deg) translateZ(250px) !important;
    -ms-transform: rotateY(51.4285714286deg) translateZ(250px) !important;
    transform: rotateY(51.4285714286deg) translateZ(250px) !important;
  }
  .carousel .slide:nth-child(3) {
    -webkit-transform: rotateY(102.8571428571deg) translateZ(250px) !important;
    -ms-transform: rotateY(102.8571428571deg) translateZ(250px) !important;
    transform: rotateY(102.8571428571deg) translateZ(250px) !important;
  }
  .carousel .slide:nth-child(4) {
    -webkit-transform: rotateY(154.2857142857deg) translateZ(250px) !important;
    -ms-transform: rotateY(154.2857142857deg) translateZ(250px) !important;
    transform: rotateY(154.2857142857deg) translateZ(250px) !important;
  }
  .carousel .slide:nth-child(5) {
    -webkit-transform: rotateY(205.7142857143deg) translateZ(250px) !important;
    -ms-transform: rotateY(205.7142857143deg) translateZ(250px) !important;
    transform: rotateY(205.7142857143deg) translateZ(250px) !important;
  }
  .carousel .slide:nth-child(6) {
    -webkit-transform: rotateY(257.1428571429deg) translateZ(250px) !important;
    -ms-transform: rotateY(257.1428571429deg) translateZ(250px) !important;
    transform: rotateY(257.1428571429deg) translateZ(250px) !important;
  }
  .carousel .slide:nth-child(7) {
    -webkit-transform: rotateY(308.5714285714deg) translateZ(250px) !important;
    -ms-transform: rotateY(308.5714285714deg) translateZ(250px) !important;
    transform: rotateY(308.5714285714deg) translateZ(250px) !important;
  }
}




  
body {
  /* margintop: 0; */
  /* overflow: hidden; */
  /* background: #000; */
}
/* Mobile version */
/* @media (max-width: 768px) {
    body .container .carousel {
      width: 189px;
      height: 252px;
      -webkit-transform: rotateX(-5deg) rotateY(0);
      -ms-transform: rotateX(-5deg) rotateY(0);
      transform: rotateX(-5deg) rotateY(0);
      -webkit-animation: turn 20s infinite linear;
      -moz-animation: turn 20s infinite linear;
      -o-animation: turn 20s infinite linear;
      animation: turn 20s infinite linear;
    }
    body .container .carousel .slide {
      width: 189px;
      height: 252px;
      -webkit-transform: translateZ(316px);
      -ms-transform: translateZ(316px);
      transform: translateZ(316px);
    }
  } */
  
body .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: auto;
  margin-top: 20px;
}
body .container .carousel {
  width: 378px;
  height: 504px;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-5deg) rotateY(0);
  -ms-transform: rotateX(-5deg) rotateY(0);
  transform: rotateX(-5deg) rotateY(0);
  -webkit-animation: turn 20s infinite linear;
  -moz-animation: turn 20s infinite linear;
  -o-animation: turn 20s infinite linear;
  animation: turn 20s infinite linear;
}
body .container .carousel .slide {
  position: absolute;
  width: 378px;
  height: 504px;
  border-radius: 8px;
  box-shadow: 0 0 4px 1px #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body .container .carousel .slide:nth-child(1) {
    -webkit-transform: rotateY(0deg) translateZ(500px);
    -ms-transform: rotateY(0deg) translateZ(500px);
    transform: rotateY(0deg) translateZ(500px);
    }
    body .container .carousel .slide:nth-child(2) {
    -webkit-transform: rotateY(51.4285714286deg) translateZ(500px);
    -ms-transform: rotateY(51.4285714286deg) translateZ(500px);
    transform: rotateY(51.4285714286deg) translateZ(500px);
    }
    body .container .carousel .slide:nth-child(3) {
    -webkit-transform: rotateY(102.8571428571deg) translateZ(500px);
    -ms-transform: rotateY(102.8571428571deg) translateZ(500px);
    transform: rotateY(102.8571428571deg) translateZ(500px);
    }
    body .container .carousel .slide:nth-child(4) {
    -webkit-transform: rotateY(154.2857142857deg) translateZ(500px);
    -ms-transform: rotateY(154.2857142857deg) translateZ(500px);
    transform: rotateY(154.2857142857deg) translateZ(500px);
    }
    body .container .carousel .slide:nth-child(5) {
    -webkit-transform: rotateY(205.7142857143deg) translateZ(500px);
    -ms-transform: rotateY(205.7142857143deg) translateZ(500px);
    transform: rotateY(205.7142857143deg) translateZ(500px);
    }
    body .container .carousel .slide:nth-child(6) {
    -webkit-transform: rotateY(257.1428571429deg) translateZ(500px);
    -ms-transform: rotateY(257.1428571429deg) translateZ(500px);
    transform: rotateY(257.1428571429deg) translateZ(500px);
    }
    body .container .carousel .slide:nth-child(7) {
    -webkit-transform: rotateY(308.5714285714deg) translateZ(500px);
    -ms-transform: rotateY(308.5714285714deg) translateZ(500px);
    transform: rotateY(308.5714285714deg) translateZ(500px);
    }