.signature-footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  /* padding-top: 4rem; /
  / background-color: #fce6f2; */
  }
  
  .signature-footer-logo {
  height: 12px;
  margin-right: 5px;
  margin-bottom: 13px;
  }
  
  .signature-footer-text {
  font-size: 10px;
  /* font-family: 'Sail', cursive; /
  color: black; / changing the text color to white */
  }
  
  .signature-footer-link {
  color: #fcbbdf;
  text-decoration: none;
  animation: color-change-5x 8s linear infinite alternate both;
  }
  
  @media only screen and (max-width: 600px) {
  .signature-footer-container {
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-right:0;
  }
  }
  
  @keyframes color-change-5x {
    0% {
      color: #fce6f2;
      /* text-shadow: 0 0 2px #fce6f2; */
    }
    20% {
      color: #fcb3d1;
      /* text-shadow: 0 0 2px #fcb3d1; */
    }
    40% {
      color: #fb80b1;
      /* text-shadow: 0 0 2px #fb80b1; */
    }
    60% {
      color: #f94d91;
      /* text-shadow: 0 0 2px #f94d91; */
    }
    80% {
      color: #fc3d80;
      /* text-shadow: 0 0 2px #fc3d80; */
    }
    100% {
      color: #fc6c9c;
      /* text-shadow: 0 0 2px #fc6c9c; */
    }
  }
  